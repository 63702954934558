import React from "react";
import copy from "clipboard-copy";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import _classes from "../styles/main.module.css";
import css from "./code-preview.module.css";
import getCode from "./code";

class Integrate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataType: 0,
      snackBarOpen: false,
      lang: "javascript",
      tab_index: 0,
      code: "",
    };

    this.tabs = ["url", "file"];
    this.tabChange = this.tabChange.bind(this);
    this.setLang = this.setLang.bind(this);
    this.copyCode = this.copyCode.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.setState({
      code: getCode(
        this.state.lang,
        this.tabs[this.state.tab_index],
        this.props.model_type
      ),
    });
  }

  tabChange(event, value) {
    this.setState({
      tab_index: value,
      code: getCode(this.state.lang, this.tabs[value], this.props.model_type),
    });
  }

  setLang(lang) {
    this.setState({
      lang,
      code: getCode(
        lang,
        this.tabs[this.state.tab_index],
        this.props.model_type
      ),
    });
  }

  copyCode() {
    copy(this.state.code);

    this.setState({
      snackBarOpen: true,
      snackBarMessage: "Code copied!",
    });
  }

  handleClose() {
    this.setState({
      snackBarOpen: false,
    });
  }

  render() {
    const { lang, snackBarOpen, snackBarMessage, code, tab_index } = this.state;

    return (
      <div>
        <div style={{ marginBottom: 20 }}>
          <Tabs
            value={tab_index}
            indicatorColor="primary"
            onChange={this.tabChange}
          >
            <Tab label="Code for URL" />
            <Tab label="Code for File" />
          </Tabs>
        </div>

        <div style={{ background: "#282c34", borderRadius: "6px" }}>
          <div style={{ textAlign: "right", padding: 20 }}>
            <button
              className={_classes.button}
              style={{ width: "auto" }}
              onClick={this.copyCode}
            >
              Copy Code
            </button>
          </div>
          <div style={{ display: "flex", paddingBottom: 20 }}>
            <div className={css.codeBox}>{code}</div>
            <div className={css.logos}>
              <img
                className={`${css.logo} ${lang === "nodejs" ? css.active : ""}`}
                src="/media/nodejs.svg"
                alt="nodejs"
                onClick={() => this.setLang("nodejs")}
              />
              <img
                className={`${css.logo} ${lang === "python" ? css.active : ""}`}
                src="/media/python.svg"
                alt="python"
                onClick={() => this.setLang("python")}
              />
              <img
                className={`${css.logo} ${lang === "go" ? css.active : ""}`}
                src="/media/go.svg"
                alt="go"
                onClick={() => this.setLang("go")}
              />
              <img
                className={`${css.logo} ${
                  lang === "javascript" ? css.active : ""
                }`}
                src="/media/javascript.svg"
                alt="javascript"
                onClick={() => this.setLang("javascript")}
              />
              <img
                className={`${css.logo} ${lang === "ruby" ? css.active : ""}`}
                src="/media/ruby.svg"
                alt="ruby"
                onClick={() => this.setLang("ruby")}
              />
              <img
                className={`${css.logo} ${lang === "csharp" ? css.active : ""}`}
                src="/media/csharp.svg"
                alt="c#"
                onClick={() => this.setLang("csharp")}
              />
              <img
                className={`${css.logo} ${lang === "java" ? css.active : ""}`}
                src="/media/java.svg"
                alt="java"
                onClick={() => this.setLang("java")}
              />
              <img
                className={`${css.logo} ${lang === "shell" ? css.active : ""}`}
                src="/media/shell.svg"
                alt="shell"
                onClick={() => this.setLang("shell")}
              />
            </div>
          </div>
        </div>

        <Snackbar
          open={snackBarOpen}
          onClose={this.handleClose}
          autoHideDuration={3000}
        >
          <Alert severity="success">{snackBarMessage}</Alert>
        </Snackbar>
      </div>
    );
  }
}

export default Integrate;
