import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Progress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import SendIcon from "@material-ui/icons/Send";
import Alert from "@material-ui/lab/Alert";

const negativeMargin = 160;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    background: "#2F419E",
  },
  contactForm: {
    backgroundColor: "#fff",
    margin: "0 auto",
    padding: 30,
    borderRadius: 8,
    boxShadow: "0 2px 8px 0 rgba(60, 64, 67, 0.2)",
    marginTop: -negativeMargin,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    zIndex: 5,
  },
  heading: {
    color: "#23325F",
    fontSize: 24,
    fontWeight: 500,
    margin: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: 28,
    },
  },
  field: {
    display: "block",
    width: "100%",
    padding: 10,
    // outline: 0,
    borderRadius: 4,
    border: "1px solid #ddd",
    background: "#f9f9f9",
    fontSize: 14,
  },
  textarea: {
    resize: "none",
    fontFamily: "inherit",
  },
}));

export default function Contact() {
  const classes = useStyles();
  const [status, setstatus] = useState("");

  function sendRequest(event) {
    event.preventDefault();
    if (status === "progress") return;

    const formData = new FormData(event.target);

    const payload = {};
    payload.page = window.location.href;
    for (const key of formData.keys()) {
      payload[key] = formData.get(key);
    }

    setstatus("progress");
    fetch("https://hooks.zapier.com/hooks/catch/4636052/o5fz1hb/", {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((res) =>
        res.ok ? res.json() : Promise.reject(new Error("An error occured!"))
      )
      .then(() => {
        window.localStorage.setItem("email", formData.get("email"));
        document.write();
        window.location.href = "/thank-you/";
      })
      .catch(() => {
        setstatus("error");
      });
  }

  return (
    <div className={classes.root} id="contact">
      <div>
        <Container>
          <div className={classes.contactForm}>
            <p className={classes.heading}>
              Get in touch - start your free trial today!
            </p>
            <p style={{ marginTop: 16, color: "#68798D", fontSize: 14 }}>
              Reach out for any questions on our Enterprise plan, pricing, or
              security. Chat with an enterprise collaboration specialist.
            </p>
            <form onSubmit={sendRequest} style={{ marginTop: 32 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Your name"
                    required
                    className={`${classes.field}`}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Work email"
                    required
                    className={`${classes.field}`}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <input
                    type="text"
                    name="company"
                    placeholder="Company name"
                    required
                    className={`${classes.field}`}
                  />
                </Grid>

                <Grid item xs={12}>
                  <textarea
                    name="message"
                    placeholder="How can we help you?"
                    rows="5"
                    minLength="50"
                    required
                    className={`${classes.field} ${classes.textarea}`}
                  />
                </Grid>

                {status === "error" && (
                  <Grid item xs={12}>
                    <Alert severity="error">
                      An error occured while sending request.
                    </Alert>
                  </Grid>
                )}

                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    type="submit"
                    style={{ width: "100%" }}
                  >
                    {status === "progress" ? (
                      <Progress color="#fff" size={25} />
                    ) : (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ marginRight: 10 }}>Send Message</span>
                        <SendIcon style={{ fontSize: 18 }} />
                      </div>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>
    </div>
  );
}
