import React from "react";
import css from "./features.module.css";

const Features = ({ features, iconWidth, iconHeight }) => {
  let iconStyle = {};
  if (iconWidth || iconHeight) {
    iconStyle = {
      width: "auto",
      height: "auto",
    };
  }
  iconWidth && (iconStyle.width = iconWidth);
  iconHeight && (iconStyle.height = iconHeight);

  return (
    <div style={{ overflow: "hidden" }}>
      <div className={css.features}>
        {features.map((feature) => (
          <div key={feature.title} id={feature.title} className={css.feature}>
            <img
              className={`lazyload ${css.icon}`}
              style={iconStyle}
              data-src={feature.icon + `?w=${iconWidth}&h=${iconHeight}`}
              alt=""
            />
            <p className={css.title}>{feature.title}</p>
            <p className={css.summary}>{feature.summary}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;

Features.defaultProps = {
  features: [
    {
      icon: "/media/fast.png",
      title: "Fast",
      summary:
        "Cut down the manual inspection time from hours to seconds using AI-powered automation. Analyze each image in milliseconds, maximizing the time for taking corrective measures which can also help increase throughput.",
    },
    {
      icon: "/media/accurate.png",
      title: "Accurate",
      summary:
        "Train state-of-the-art deep learning models on you own data ensuring highest accuracy compared to publicly available models or ready-to-use solutions.",
    },
    {
      icon: "/media/consistent.png",
      title: "Consistent",
      summary:
        "Get rid of the subjectivity in human judgement while inspecting objects for non-trivial defects. Be assured of consistency in reports for the same inspection data.",
    },
    {
      icon: "/media/scale.png",
      title: "Scale",
      summary:
        "Robust autoscaling capable of analyzing billions of images each month. Scale your compute requirements to meet your desired throughput.",
    },
    {
      icon: "/media/flexible.png",
      title: "Flexible",
      summary:
        "Deploy trained models on-prem using Docker images ensuring that your data does not have to leave your network or deploy models on our production optimized cloud instances for maximum performance.",
    },
  ],
};
