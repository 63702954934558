export default function (language, input_type, model_type, model_id) {
  let modelId = model_id ? model_id : "_MODEL_ID";
  let apiKey = "YOUR_API_KEY";

  let predictWithFiles =
    "https://app.nanonets.com/api/v2/ImageCategorization/LabelFile/";
  let predictWithUrls =
    "https://app.nanonets.com/api/v2/ImageCategorization/LabelUrls/";
  let formDataKeyForFiles = "file";

  if (model_type === "mlc") {
    predictWithFiles = `https://app.nanonets.com/api/v2/MultiLabelClassification/Model/${model_id}/LabelFiles/`;
    predictWithUrls = `https://app.nanonets.com/api/v2/MultiLabelClassification/Model/${model_id}/LabelUrls/`;
    formDataKeyForFiles = "files";
  }

  const code = {
    nodejs: {
      file: `var request = require('request');   // install request module by - 'npm install request'
var fs = require('fs')

const form_data = {
    'modelId' : '${modelId}',
    '${formDataKeyForFiles}': fs.createReadStream('test.jpg'),
}

const options = {
    url : '${predictWithFiles}',
    formData: form_data,
    headers: {
        'Authorization' : 'Basic ' + Buffer.from('${apiKey}' + ':').toString('base64')
    }
}
request.post(options, function(err, httpResponse, body) {
    console.log(body);
});`,
      url: `var request = require('request');   // install request module by - 'npm install request'
var querystring = require('querystring')

const form_data = {
    'modelId' : '${modelId}',
    'urls': 'https://goo.gl/ICoiHc'
}

const options = {
    url : '${predictWithUrls}',
    body: querystring.stringify(form_data),
    headers: {
        'Authorization' : 'Basic ' + Buffer.from('${apiKey}' + ':').toString('base64'),
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}
request.post(options, function(err, httpResponse, body) {
    console.log(body);
});`,
    },
    go: {
      file: `package main

import (
  "bytes"
  "fmt"
  "io"
  "io/ioutil"
  "mime/multipart"
  "net/http"
  "os"
  "path/filepath"
)

func main() {

  url := "${predictWithFiles}"

  file, err := os.Open("test.jpg")
  if err != nil {
    fmt.Println(err)
    return
  }

  defer file.Close()

  body := &bytes.Buffer{}
  writer := multipart.NewWriter(body)

  part, err := writer.CreateFormFile("${formDataKeyForFiles}", filepath.Base("test.jpg"))
  if err != nil {
    fmt.Println(err)
    return
  }
  _, err = io.Copy(part, file)

  writer.WriteField("modelId", "${modelId}")

  contentType := writer.FormDataContentType()

  err = writer.Close()
  if err != nil {
    fmt.Println(err)
    return
  }

  req, _ := http.NewRequest("POST", url, body)

  req.Header.Add("Content-Type", contentType)
  req.SetBasicAuth("${apiKey}", "")

  res, _ := http.DefaultClient.Do(req)

  defer res.Body.Close()
  output, _ := ioutil.ReadAll(res.Body)

  fmt.Println(res)
  fmt.Println(string(output))
}`,
      url: `package main

import (
  "bytes"
  "fmt"
  "io/ioutil"
  "net/http"
  "net/url"
)

func main() {

  labelUrl := "${predictWithUrls}"

  data := url.Values{}
  data.Set("modelId", "${modelId}")
  data.Add("urls", "https://goo.gl/ICoiHc")

  req, _ := http.NewRequest("POST", labelUrl, bytes.NewBufferString(data.Encode()))

  req.Header.Add("Content-Type", "application/x-www-form-urlencoded")
  req.SetBasicAuth("${apiKey}", "")

  res, _ := http.DefaultClient.Do(req)

  defer res.Body.Close()
  body, _ := ioutil.ReadAll(res.Body)

  fmt.Println(res)
  fmt.Println(string(body))

}`,
    },
    python: {
      file: `import requests

url = '${predictWithFiles}'

data = {'${formDataKeyForFiles}': open('REPLACE_IMAGE_PATH.jpg', 'rb'), 'modelId': ('', '${modelId}')}

response = requests.post(url, auth= requests.auth.HTTPBasicAuth('${apiKey}', ''), files=data)

print(response.text)`,
      url: `import requests

url = '${predictWithUrls}'

headers = {
  'accept': 'application/x-www-form-urlencoded'
}

data = {'modelId': '${modelId}', 'urls' : ['https://goo.gl/ICoiHc']}

response = requests.request('POST', url, headers=headers, auth=requests.auth.HTTPBasicAuth('${apiKey}', ''), data=data)

print(response.text)`,
    },
    javascript: {
      file: `var data = new FormData();
data.append('modelId', '${modelId}');
data.append('file', file); // file is a Blob object

var xhr = new XMLHttpRequest();

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === this.DONE) {
    console.log(this.responseText);
  }
});

xhr.open("POST", "${predictWithFiles}");
xhr.setRequestHeader("authorization", "Basic " + btoa("${apiKey}:"));

xhr.send(data);`,
      url: `var data = 'modelId=${modelId}&urls=https://goo.gl/ICoiHc';

var xhr = new XMLHttpRequest();

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === this.DONE) {
    console.log(this.responseText);
  }
});

xhr.open("POST", "${predictWithUrls}");
xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
xhr.setRequestHeader("authorization", "Basic " + btoa("${apiKey}:"));

xhr.send(data);`,
    },
    shell: {
      file: `curl --request POST \
--url '${predictWithFiles}' \
--header 'accept: multipart/form-data' \
-u 'XGXt4v0JeN0DqPJ3d06pFizro1IGbV1JKhVYojIGice' \
-F 'modelId=${modelId}' \
-F '${formDataKeyForFiles}=@REPLACE_IMAGE_PATH.jpg'`,
      url: `curl --request POST \
--url '${predictWithUrls}' \
--header 'accept: application/x-www-form-urlencoded' \
-d 'modelId=${modelId}&urls=https://goo.gl/ICoiHc' \
-u 'XGXt4v0JeN0DqPJ3d06pFizro1IGbV1JKhVYojIGice'`,
    },
    ruby: {
      file: `require 'uri'
require 'net/http'
require 'net/http/post/multipart'

url = URI('${predictWithFiles}')

http = Net::HTTP.new(url.host, url.port)
http.use_ssl = true

File.open('test.jpg') do |jpg|
request = Net::HTTP::Post::Multipart.new(url.path,
  '${formDataKeyForFiles}' => UploadIO.new(jpg, 'image/jpeg', 'test.jpg'),
  'modelId' => '${modelId}')
request.basic_auth '${apiKey}', ''
response = Net::HTTP.start(url.host, url.port) do |http|
http.request(request)
end
puts response.read_body
end`,
      url: `require 'uri'
require 'net/http'
url = URI('${predictWithUrls}')
http = Net::HTTP.new(url.host, url.port)
http.use_ssl = true
request = Net::HTTP::Post.new(url)
request["accept"] = 'application/x-www-form-urlencoded'
request.basic_auth '${apiKey}', ''
request.set_form_data({'modelId' => '${modelId}', 'urls' => ['https://goo.gl/ICoiHc']})
response = http.request(request)
puts response.read_body`,
    },
    csharp: {
      file: `var client = new RestClient("${predictWithFiles}");
var request = new RestRequest(Method.POST);
request.AddHeader("authorization", "Basic " + Convert.ToBase64String(Encoding.Default.GetBytes("${apiKey}:")));
request.AddHeader("accept", "Multipart/form-data");
request.AddParameter("modelId", "${modelId}");
request.AddFile("${formDataKeyForFiles}", "REPLACE_IMAGE_PATH.jpg");
IRestResponse response = client.Execute(request);`,
      url: `var client = new RestClient("${predictWithUrls}");
var request = new RestRequest(Method.POST);
request.AddHeader("authorization", "Basic " + Convert.ToBase64String(Encoding.Default.GetBytes("${apiKey}:")));
request.AddHeader("accept", "application/x-www-form-urlencoded");
request.AddParameter("modelId", "${modelId}");
request.AddParameter("urls", "https://goo.gl/ICoiHc");
IRestResponse response = client.Execute(request);`,
    },
    java: {
      file: `OkHttpClient client = new OkHttpClient();
private static final MediaType MEDIA_TYPE_JPG = MediaType.parse("image/jpeg");

OkHttpClient client = new OkHttpClient();

RequestBody requestBody = new MultipartBody.Builder()
  .setType(MultipartBody.FORM)
  .addFormDataPart("modelId", "${modelId}")
  .addFormDataPart("${formDataKeyForFiles}", "REPLACE_IMAGE_PATH.jpg", RequestBody.create(MEDIA_TYPE_JPG, new File("REPLACE_IMAGE_PATH.jpg")))
  .build();

request = new Request.Builder()
  .url("${predictWithFiles}")
  .post(requestBody)
  .addHeader("Authorization", Credentials.basic("${apiKey}", ""))
  .build();

Response response = client.newCall(request).execute();`,
      url: `OkHttpClient client = new OkHttpClient();

RequestBody formBody = new FormBody.Builder()
  .add("modelId", "${modelId}")
  .add("urls", "https://goo.gl/ICoiHc").build();

Request request = new Request.Builder()
  .url("${predictWithUrls}")
  .post(formBody)
  .addHeader("Authorization", Credentials.basic("${apiKey}", ""))
  .build();

Response response = client.newCall(request).execute();`,
    },
  };

  return code[language][input_type];
}
