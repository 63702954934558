import React from "react";
import { graphql } from "gatsby";
import clsx from "clsx";
import Container from "@material-ui/core/Container";
import _classes from "../styles/main.module.css";
import css from "./model.module.css";
import Cards from "../components/cards";
import CodePreview from "../components/code-preview";
import Contact from "../components/contact";
import Demo from "../components/demo";
import Features from "../components/features";
import Img from "../components/img";
import Layout from "../components/layout";
import ModelSteps from "../components/model-steps";
import Spacer from "../components/spacer";
import SEO from "../components/seo";

const Model = ({ data }) => {
  const fm = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <SEO title={fm.seoTitle} description={fm.seoDescription} />

      <Container>
        <Spacer h={120} />

        <h1
          style={{
            fontSize: 40,
            fontWeight: "normal",
            margin: "0 0 32px",
            textAlign: "center",
          }}
        >
          {fm.page_title}
        </h1>

        <Demo demo={fm.demo} />

        <Spacer h={40} />

        <div style={{ textAlign: "center" }}>
          <a
            className={_classes.button}
            style={{ margin: 20 }}
            href="https://app.nanonets.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get Started
          </a>
          <a
            className={clsx(_classes.button, _classes.button_outlined)}
            style={{ margin: 20 }}
            href="#contact"
          >
            Contact Sales
          </a>
        </div>
      </Container>

      {fm.training_images && fm.training_images.length > 0 && (
        <>
          <Spacer h={60} />
          <div style={{ background: "#f2f2f2", padding: "50px 0" }}>
            <Container>
              <h6 className={css.heading} style={{ fontSize: 20 }}>
                Training Data
              </h6>

              <div className={css.test_images}>
                {fm.training_images.map((item, index) => (
                  <div className={css.imgWrapper} key={index}>
                    <Img
                      className={`lazyload ${css.test_image}`}
                      src={`${item.image}?h=150`}
                    />
                  </div>
                ))}
              </div>
            </Container>
          </div>
        </>
      )}

      <Spacer h={100} />

      <Container>
        <div className={css.meta}>
          {fm.metadata.map((item) => (
            <div key={item.label} className={css.meta_item}>
              <span className={css.label}>{item.label}</span>
              <span className={css.value}>{item.value}</span>
            </div>
          ))}
        </div>

        {fm.QA.show && (
          <>
            <Spacer h={100} />
            <div className={css.grid2}>
              <div>
                <h6 className={css.heading}>What does the model do</h6>
                {fm.QA.what_model_deos.summary}

                {fm.QA.what_model_deos.link && (
                  <>
                    <Spacer h={40} />
                    <a
                      href={fm.QA.what_model_deos.link}
                      className={clsx(
                        _classes.button,
                        _classes.button_outlined
                      )}
                    >
                      Learn More
                    </a>
                  </>
                )}
              </div>
              <div>
                <h6 className={css.heading}>How you can customize</h6>
                {fm.QA.how_to_customize.summary}

                {fm.QA.how_to_customize.link && (
                  <>
                    <Spacer h={40} />
                    <a
                      href={fm.QA.how_to_customize.link}
                      className={clsx(
                        _classes.button,
                        _classes.button_outlined
                      )}
                    >
                      Learn More
                    </a>
                  </>
                )}
              </div>
            </div>
          </>
        )}

        <Spacer h={100} />

        <CodePreview model_type={fm.model_type} />

        <Spacer h={100} />

        {fm.how_it_works.show && (
          <div style={{ maxWidth: 800, margin: "auto" }}>
            <ModelSteps how_it_works={fm.how_it_works} />
          </div>
        )}

        {fm.feature_data.show && (
          <>
            <Spacer h={100} />
            <h3 className={css.heading2}>{fm.feature_data.heading}</h3>
            <Features features={fm.feature_data.features} />
          </>
        )}

        {fm.case_study.cards && fm.case_study.cards.length > 0 && (
          <>
            <Spacer h={100} />
            <div id="case-study" style={{ textAlign: "center" }}>
              <h3 className={css.heading2}>Case Studies</h3>
            </div>
            <Cards cards={fm.case_study.cards} />
          </>
        )}

        <Spacer h={100} />

        <div style={{ textAlign: "center" }}>
          <h3 className={css.heading2}>Explore our other models</h3>
        </div>
        <Cards cards={fm.other_models.cards} />
      </Container>

      <div style={{ marginTop: 320 }}></div>

      <Contact />
    </Layout>
  );
};

export default Model;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        path
        seoTitle
        seoDescription
        page_title
        model_type
        QA {
          show
          what_model_deos {
            summary
            link
          }
          how_to_customize {
            summary
            link
          }
        }
        training_images {
          image
        }
        metadata {
          label
          value
        }
        demo {
          input
          output {
            image {
              url
            }
            result
          }
          demo_type
          output_type
        }
        how_it_works {
          show
          heading
          heading2
          steps {
            name
            summary
            poster
          }
        }
        feature_data {
          show
          heading
          features {
            icon
            title
            summary
          }
        }
        case_study {
          cards {
            href
            poster
            summary
            title
          }
        }
        other_models {
          cards {
            href
            poster
            summary
            title
          }
        }
      }
    }
  }
`;
