import React from "react";
import Carousel from "../components/carousel";
import css from "./model-steps.module.css";

class ModelSteps extends React.Component {
  constructor(props) {
    super(props);
    this.stepsRef = React.createRef();
    this.sliderUpdate = this.sliderUpdate.bind(this);
  }

  componentDidMount() {
    const steps = this.stepsRef.current.children;
    if (steps) {
      steps[0].classList.add(css.active);
    }
  }

  sliderUpdate(prevIndex, nextIndex) {
    const steps = this.stepsRef.current.children;
    if (steps) {
      for (let i = 0; i < steps.length; i++) {
        const step = steps[i];
        step.classList.remove(css.prev);
        step.classList.remove(css.active);

        if (i < nextIndex) {
          step.classList.add(css.prev);
        }
        if (i === nextIndex) {
          step.classList.add(css.active);
        }
      }
    }
  }

  render() {
    const { how_it_works } = this.props;
    return (
      <div>
        <div style={{ textAlign: "center", marginBottom: 50 }}>
          <h2
            style={{ fontSize: 36, margin: "0 0 20px", fontWeight: "normal" }}
          >
            {how_it_works.heading}
          </h2>
          <p style={{ fontSize: 18, margin: "0 0 20px" }}>
            {how_it_works.heading2}
          </p>
        </div>
        <div className={css.steps} ref={this.stepsRef}>
          {how_it_works &&
            how_it_works.steps.map((step, index) => (
              <div key={index} className={css.stepLabel}>
                <div className={css.stepIcon}>
                  <span className={css.stepIconText}>{index + 1}</span>
                  <span className={css.connector}></span>
                </div>
                <p className={css.stepHeading}>{step.name}</p>
              </div>
            ))}
        </div>

        <Carousel
          options={{
            autoplay: true,
            autoplayInterval: 3000,
            wrapAround: true,
            beforeSlide: this.sliderUpdate,
          }}
        >
          {how_it_works &&
            how_it_works.steps.map((step, index) => (
              <div key={index} className={css.step}>
                <p className={css.stepSummary}>{step.summary}</p>
                <div className={css.posterWrapper}>
                  <img
                    className={`${css.poster} lazyload`}
                    data-src={step.poster}
                    alt=""
                  />
                </div>
              </div>
            ))}
        </Carousel>
      </div>
    );
  }
}

export default ModelSteps;
